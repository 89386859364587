import BaseLayout from "@components/BaseLayout"
import SEO from "@components/Seo"
import Translate from "@components/Translate"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const NotFoundPage = () => {
  const { metatag } = useStaticQuery(graphql`
    query NotFound {
      metatag: markdownRemark(
        fileAbsolutePath: { glob: "**/content/metatags/notFound.md" }
      ) {
        frontmatter {
          languages {
            language
            keywords
            title
            description
          }
        }
      }
    }
  `)

  const metatags = metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )

  return (
    <BaseLayout>
      <SEO {...metatags} />
      <h1>
        <Translate id="pageNotFound" />
      </h1>
    </BaseLayout>
  )
}

export default NotFoundPage
